/* eslint-disable */
import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import { history } from "../../../config/stores";
import Scrollbars from "react-custom-scrollbars";
import Statistics from "../../../components/Statistics";
import "../styles.scss";
import Popup from "reactjs-popup";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import AgenciesModal from "./AgenciesModal";

const headerElements = [
  {
    name: "Campaign title",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Code",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Status",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Start date",
    query: { filter: "Role", options: [] },
  },
  {
    name: "End date",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Appy for",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Discount",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Discount for",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Attached to users",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Used",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Last update",
    query: { filter: "Role", options: [] },
  },
];

const DiscountsGrid = ({ docs, loading, setLoading, getDiscounts }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);
  const [openModal, setOpenModal] = useState({ open: false, id: "" });

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && docs.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getDiscounts({
        pageNumber: curPage,
        pageSize: 40,
        pagination: true,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getDiscounts({
      pageNumber: 1,
      pageSize: 40,
      pagination: true,
      onSuccess: () => setLoading(false),
    });
  }, []);

  return (
    <>
      {loading === true ? (
        <Statistics.LoaderInline />
      ) : (
        <Scrollbars style={{ height: "93%", width: "100%", marginTop: "2%" }} onUpdate={handleUpdate} ref={tableRef} id={"scrollbar"}>
          <div className="table-header semi-bigger">
            {headerElements?.map((header, i) => {
              return (
                <div
                  className="header-cont"
                  key={i}
                  style={{
                    width: i === 0 ? "12%" : "8.5%",
                  }}
                >
                  <div className="header">{header.name}</div>
                </div>
              );
            })}
          </div>
          {docs?.docs?.map((doc, i) => {
            return (
              <div className="table-body-row semi-bigger" key={i}>
                <div className="discount bigger row-data">
                  <div className={`flex-container align-center full-width`}>
                    <p style={{ width: "90%" }}>{doc?.name || "---"}</p>
                    {doc?.changes?.filter((change) => change?.action === "деактивира")?.length > 0 && (
                      <Popup
                        on={["hover"]}
                        className="popUp_options"
                        trigger={(open) => {
                          return <div className="info-icon black" style={{ width: "15px", height: "15px", margin: "0px 15px" }}></div>;
                        }}
                      >
                        {(close) => {
                          return (
                            <>
                              <p>Деактивиран</p>
                            </>
                          );
                        }}
                      </Popup>
                    )}
                  </div>
                </div>
                <div className="discount row-data">{doc?.discountCode || "---"}</div>
                <div className="discount row-data">{moment().isBetween(moment(doc?.startDate), moment(doc?.endDate)) ? "Active" : moment(doc?.endDate).isBefore(moment()) ? "Expired" : doc?.endDate ? "Upcoming" : "Active"}</div>
                <div className="discount row-data">{doc?.startDate ? moment(doc?.startDate).format("DD.MM.YYYY") : "---"}</div>
                <div className="discount row-data">{doc?.endDate ? moment(doc?.endDate).format("DD.MM.YYYY") : "---"}</div>
                <div className="discount row-data">{doc?.discountCondition === "all" ? "All orders" : "First order"}</div>
                <div className="discount row-data">{doc?.discount ? doc?.discount + `${doc?.discountInPercent ? "%" : "лв."}` : "---"}</div>
                <div className="discount row-data">
                  <div className={`flex-container align-center full-width `}>
                    <p>{doc?.policyTypes?.length ? doc?.policyTypes?.length + " insurances" : "All insurances"}</p>
                    {doc?.policyTypes?.length ? <div className="info-icon black" style={{ width: "15px", height: "15px", marginLeft: "15px" }} onClick={() => setOpenModal({ open: true, id: doc?._id })} /> : null}
                  </div>
                </div>
                <div className="discount row-data">{doc?.attachedCount + " users"}</div>
                <div className="discount row-data">{doc?.used ? doc.used + "times" : "0 times"}</div>
                <div className="row-data" style={{ width: "9.5%" }}>
                  <div className="flex-container full-width space-between">
                    <p>{doc?.startDate ? moment(doc?.updatedAt).format("DD.MM.YYYY") : "---"}</p>
                    <div className="edit-icon blue" onClick={() => history.push(`/discount/edit/${doc?._id}`)} />
                  </div>
                </div>
              </div>
            );
          })}
          {innerLoading && (
            <div className="inner-loader-container">
              <Statistics.LoaderInline center />
            </div>
          )}
        </Scrollbars>
      )}
      <ModalComponent open={openModal.open} position={"right"} children={<AgenciesModal handleClose={() => setOpenModal({ open: false, id: "" })} id={openModal.id} />} />
    </>
  );
};

export default DiscountsGrid;
