/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getStatisticsSuccess, getIncomeRevenue } from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import moment from "moment";
import { options } from "../../../config/constants";

const horizontalObjects = [
  {
    head: "Insurances",
    valueKey: "OrderedInsurancePolicyIncome",
    labelKey: "OrderedInsurancePolicyRevenue",
  },
  {
    head: "Subscriptions",
    valueKey: "SubscriptionIncome",
    labelKey: "SubscriptionRevenue",
  },
  {
    head: "Insurance installments",
    valueKey: "MultipleInsurancePolicyInstallmentsIncome",
    labelKey: "MultipleInsurancePolicyInstallmentsRevenue",
  },
  {
    head: "Customer support payment",
    valueKey: "CustomerSupportPaymentIncome",
    labelKey: "GeoWashRevenue",
  },
  {
    head: "Vignettes",
    valueKey: "VignetteIncome",
    labelKey: "VignetteRevenue",
  },
  {
    head: "Tickets and fines",
    valueKey: "FineIncome",
    labelKey: "FineRevenue",
  },
  {
    head: "Geo wash - car wash",
    valueKey: "GeoWashIncome",
    labelKey: "GeoWashRevenue",
  },
  {
    head: "Vignette fines",
    valueKey: "VignetteFineIncome",
    labelKey: "VignetteFineRevenue",
  },
];

const moreHorizontalObjects = [
  {
    head: "COMPLETED TRANSACTIONS",
    valueKey: "byTransactions",
  },
  {
    head: "AMOUNT SPENT",
    valueKey: "byAmountSpent",
  },
];
const Sessions = ({ getStatisticsSuccess, getIncomeRevenue, incomes }) => {
  const [selPeriod, setSelPeriod] = useState({
    startDate: options[0].value,
    endDate: moment().endOf("day").toISOString(),
  });

  const [filtered, setFiltered] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);

  useEffect(() => {
    getStatisticsSuccess({ type: "activeUsers", loading: true });
    getIncomeRevenue({
      startDate: options[0].value,
      endDate: moment().endOf("day").toISOString(),
    });
  }, []);

  useEffect(() => {
    if (filtered) {
      setInnerLoading(true);
      getIncomeRevenue({
        ...selPeriod,
        onSuccess: (res) => {
          setInnerLoading(false);
        },
      });
    }
  }, [selPeriod, filtered]);

  console.log(selPeriod);
  return (
    <>
      <div className="statistics-sessions-container col">
        {innerLoading === true ? (
          <Statistics.LoaderInline />
        ) : (
          <>
            <div style={{ display: "flex", width: "100%" }}>
              <h2 className="main-header">
                <b>income & revenue</b>
              </h2>
              <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} startField={"startDate"} endField={"endDate"} />
            </div>
            <div className="border-container">
              <div style={{ display: "flex", alignItems: "center", width: "99%" }}>
                <div className="indicator-circle gray" />
                <h2 className="statistics-info-box-text" style={{ width: "95%" }}>
                  {incomes?.income || "---"} <span style={{ fontSize: "17px", marginRight: "1%" }}>BGN</span> INCOME
                </h2>
              </div>
            </div>
            <div className="statistics-sessions-content">
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4>BY STREAM</h4>
                {horizontalObjects.map((obj, i) => {})}
                {incomes?.byStream &&
                  !!Object.keys(incomes?.byStream)?.length &&
                  Object.keys(incomes?.byStream)
                    ?.filter((key) => key?.includes("Income"))
                    ?.map((key, i) => {
                      return (
                        <React.Fragment key={key}>
                          <div
                            style={{
                              margin: "5px 0px",
                              borderBottom: i < horizontalObjects.length - 1 && "1px solid #B5BCE3",
                              paddingBottom: "20px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>{horizontalObjects.filter((obj) => obj.valueKey === key)?.[0]?.head || key?.replace("Income", "")}</h4>
                            </div>
                            {incomes.loading ? (
                              <Statistics.LoaderInline />
                            ) : (
                              <Statistics.Horizontal
                                data={[
                                  {
                                    value: "BGN",
                                    label: Number(incomes?.byStream?.[key]).toFixed(2),
                                  },
                                ]}
                              />
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
              </div>
              {moreHorizontalObjects.map((obj, i) => {
                return (
                  <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }} key={i}>
                    <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>{obj.head}</h4>
                    {incomes.loading ? (
                      <Statistics.LoaderInline />
                    ) : (
                      <>
                        <Statistics.Horizontal
                          data={
                            incomes?.[obj.valueKey] &&
                            Object.keys(incomes?.[obj.valueKey])?.map((trans) => {
                              return {
                                value: trans,
                                label: incomes?.[obj.valueKey]?.[trans],
                              };
                            })
                          }
                        />
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  incomes: state.dashboard.incomes,
});
const mapDispatchToProps = (dispatch) => ({
  getIncomeRevenue: (payload) => dispatch(getIncomeRevenue(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
