/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getStatisticsSuccess, getActiveUsers, getTotalAppDownloads } from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import moment from "moment";
import { options } from "../../../config/constants";
import { history } from "../../../config/stores";

const Sessions = ({ getStatisticsSuccess, getActiveUsers, activeUsers, getTotalAppDownloads, totalDownloads, activeSessions }) => {
  const [selPeriod, setSelPeriod] = useState({
    startDate: options[0].value,
    endDate: moment().endOf("day").toISOString(),
  });

  const [filtered, setFiltered] = useState(false);

  useEffect(() => {
    getStatisticsSuccess({ type: "activeUsers", loading: true });
    getActiveUsers({
      startDate: options[0].value,
      endDate: moment().endOf("day").toISOString(),
    });
    getTotalAppDownloads({
      startDate: options[0].value,
      endDate: moment().endOf("day").toISOString(),
    });
  }, []);

  useEffect(() => {
    if (filtered) {
      getStatisticsSuccess({ type: "activeUsers", loading: true });
      getActiveUsers({ ...selPeriod });
      getTotalAppDownloads({ ...selPeriod });
    }
  }, [selPeriod]);

  return (
    <>
      <div className="statistics-sessions-container col" style={{ maxHeight: "9%" }}>
        <div
          className="statistics-sessions-header row"
          style={{
            backgroundColor: activeUsers?.activeAppSessions === 0 ? "#C7C7C7" : "#0077FF",
            borderColor: activeUsers?.activeAppSessions === 0 ? "#C7C7C7" : "#0077FF",
            margin: "0px",
            marginTop: "10px",
            marginBottom: "20px",
            justifyContent: "flex-start",
          }}
        >
          <div className="indicator" />
          <h2 style={{ paddingBottom: "0px", marginLeft: "10px", width: "96%" }}>
            {activeUsers?.activeAppSessions < 1 ? (
              "THERE ARE NO ACTIVE USERS"
            ) : (
              <>
                <span>{activeSessions?.activeSessions}</span> <b>ACTIVE USERS RIGHT NOW</b>
              </>
            )}
          </h2>
          {activeSessions?.activeSessions > 0 && <div className="info-icon" onClick={() => history.push("/active-users")} />}
        </div>
      </div>
      <div className="statistics-sessions-container col" style={{ maxHeight: window.innerWidth < 1200 ? "42%" : "35%" }}>
        <div className="statistics-sessions-content">
          <div className="statistics-sessions-section col">
            <div
              style={{
                display: "flex",
                height: "100%",
              }}
            >
              <div className="indicator-circle gray" />
              <h2 className="statistics-info-box-text" style={{ width: "95%" }}>
                {Number(totalDownloads?.["0"]?.totalDownloads) + Number(totalDownloads?.["1"]?.totalDownloads)} APP DOWNLOADS
              </h2>
              <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} startField={"startDate"} endField={"endDate"} />
            </div>
          </div>
        </div>
        <div className="statistics-sessions-section col">
          {totalDownloads.loading || activeUsers.loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
              <Statistics.Pie
                colors={["#0077FF", "#002C78"]}
                height
                data={[
                  {
                    label: totalDownloads?.["0"]?.totalDownloads,
                    value: totalDownloads?.["0"]?.operationSystem?.toUpperCase(),
                  },
                  {
                    label: totalDownloads?.["1"]?.totalDownloads,
                    value: totalDownloads?.["1"]?.operationSystem?.toUpperCase(),
                  },
                ]}
              />
            </>
          )}
        </div>
      </div>
      <div className="statistics-sessions-container col" style={{ maxHeight: "32%" }}>
        <div className="statistics-sessions-content">
          <div className="statistics-sessions-section col">
            <div
              style={{
                display: "flex",
                height: "100%",
              }}
            >
              <div className="indicator-circle gray" />
              <h2 className="statistics-info-box-text" style={{ width: "95%" }}>
                {activeUsers?.newlyRegistered} NEWLY REGISTERED
              </h2>
              <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} startField={"startDate"} endField={"endDate"} />
            </div>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="statistics-sessions-section col" style={{ width: "50%", marginBottom: "0rem" }}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <div className="indicator-circle gray" />
                <h2 className="statistics-info-box-text" style={{ width: "95%" }}>
                  {activeUsers?.fastChecks}
                </h2>
                {/*<div className="info-icon black" />*/}
              </div>
              <div style={{ marginTop: "10px" }}>
                <h2>FAST CHECK</h2>
                <p style={{ width: "80%", marginTop: "5px" }}>Based on the total number of registered users for the period</p>
              </div>
            </div>
            <div className="statistics-sessions-section col" style={{ width: "50%", marginLeft: "1%" }}>
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <div className="indicator-circle gray" />
                <h2 className="statistics-info-box-text" style={{ width: "95%" }}>
                  {activeUsers?.verified}
                </h2>
                {/*<div className="info-icon black" /> */}
              </div>
              <div style={{ marginTop: "10px" }}>
                <h2>VERIFIED PROFILES</h2>
                <p style={{ width: "80%", marginTop: "5px" }}>Based on the total number of registered users for the period</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeUsers: state.dashboard.activeUsers,
  totalDownloads: state.dashboard.totalDownloads,
  activeSessions: state.dashboard.activeSessions,
});
const mapDispatchToProps = (dispatch) => ({
  getActiveUsers: (payload) => dispatch(getActiveUsers(payload)),
  getTotalAppDownloads: (payload) => dispatch(getTotalAppDownloads(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
