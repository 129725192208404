/* eslint-disable */
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";
import { getAds, getAllFilters } from "../../actions";
import { connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Statistics from "../../components/Statistics";
import "./styles.scss";
import UsersModal from "./UsersModal";
import { User } from "../../utilites";
import AccessModal from "./AccessModal";

const headerElements = [
  {
    name: "Campaign",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Status",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Start date",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "End date",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Budget",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Advertiser",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Targets",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Access",
    query: { filter: "Role", options: [] },
  },
];

const Ads = ({ getAds, ads, getAllFilters, filters }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [usersModal, setUsersModal] = useState({
    data: "",
    open: false,
    reach: {},
  });
  const [accessModal, setAccessModal] = useState({
    data: null,
    open: false,
  });

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0

    if (t >= 0.97 && ads.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getAds({
        pageNumber: curPage,
        pageSize: 20,
        onSuccess: () => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    setInitialLoading(true);
    getAds({
      pageNumber: 1,
      pageSize: 20,
      onSuccess: (res) => setInitialLoading(false),
    });
    getAllFilters();
  }, []);

  return (
    <div className="main-container">
      <div className="body-container" style={{ height: "100%", marginTop: "0%" }}>
        <div className="flex-container space-between full-width" style={{ marginBottom: "20px" }}>
          <h2>ADVERTISING CAMPAIGNS</h2>
          {User.getRole() === "admin" && <Inputs.Button text="CREATE" className={"dark-selected"} style={{ width: "10%" }} onClick={() => history.push("/advertisers/create-campaign")} />}
        </div>
        {initialLoading ? (
          <Statistics.LoaderInline />
        ) : ads?.docs?.length > 0 ? (
          <Scrollbars style={{ height: "92%", width: "100%" }} onUpdate={handleUpdate} ref={tableRef} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: i === 0 ? "26%" : i === 5 ? "16%" : "9%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {ads?.docs?.map((ad, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "26%" }} onClick={() => history.push(`/advertisers/campaign/${ad._id}`)}>
                    {ad?.name || "---"}
                  </div>
                  <div className="row-data" style={{ width: "9%" }}>
                    {moment(ad?.startDate).isAfter(moment()) ? "Upcoming" : moment(ad?.endDate).isBefore(moment()) ? "Expired" : "Active"}
                  </div>
                  <div className="row-data" style={{ width: "9%" }}>
                    {moment(ad?.startDate).format("DD.MM.YYYY")}
                  </div>
                  <div className="row-data" style={{ width: "9%" }}>
                    {moment(ad?.endDate).format("DD.MM.YYYY")}
                  </div>
                  <div className="row-data" style={{ width: "9%" }}>
                    {ad?.budget + " BGN" || "---"}
                  </div>
                  <div className="row-data" style={{ width: "16%" }}>
                    {ad?.advertiser || "---"}
                  </div>
                  <div className="row-data" style={{ width: "9%" }}>
                    {ad?.potentialReach || "---"}
                    {ad?.potentialReach ? (
                      <div
                        className="info-icon black"
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          setUsersModal({
                            open: true,
                            data: ad?.userFindQuery,
                            reach: { all: ad?.potentialReach, agreement: ad?.potentialReachWithAgreement },
                          })
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row-data" style={{ width: "9%" }}>
                    {ad?.users?.length + " users" || "---"}
                    <div
                      className="info-icon black"
                      style={{ marginLeft: "10px" }}
                      onClick={() =>
                        setAccessModal({
                          open: true,
                          data: ad?.users,
                        })
                      }
                    />
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        ) : (
          <div className="empty-state-container" />
        )}
      </div>
      <ModalComponent
        open={usersModal.open}
        position={"right"}
        children={
          <UsersModal
            data={usersModal.data}
            reach={usersModal.reach}
            filters={filters?.insuranceAgencies}
            handleClose={() =>
              setUsersModal({
                open: false,
                data: "",
              })
            }
          />
        }
      />
      <ModalComponent
        open={accessModal.open}
        position={"right"}
        children={
          <AccessModal
            data={accessModal.data}
            handleClose={() =>
              setAccessModal({
                open: false,
                data: null,
              })
            }
          />
        }
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  reached: state.ads.reached,
  ads: state.ads.ads,
  filters: state.ads.filters,
});
const mapDispatchToProps = (dispatch) => ({
  getAds: (payload) => dispatch(getAds(payload)),
  getAllFilters: (payload) => dispatch(getAllFilters(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Ads);
