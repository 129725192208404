/* eslint-disable */
import React, { useState, useEffect } from "react";
import Inputs from "../../../components/Inputs";
import { deactivateActiveDiscount, getCurrentDiscount, getInsuranceAgencies, editUpcomingDiscount, startLoading, stopLoading } from "../../../actions";
import { connect } from "react-redux";
import "../styles.scss";
import { useForm } from "react-hook-form";
import GeneralInformation from "./Fields/GeneralInformation";
import DiscountInformation from "./Fields/DiscountInformation";
import AttachToUsers from "./Fields/AttachToUsers";
import { toast } from "react-toastify";
import { history } from "../../../config/stores";
import moment from "moment";
import { useRef } from "react";
import _ from "lodash";

const insurancesOptions = [
  {
    label: "General liability",
    value: "go",
    companies: [
      /*all*/
    ],
  },

  {
    label: "Property insurance",
    value: "property",
    companies: [{ insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" }],
  },

  {
    label: "Autocasco",
    value: "kasko",
    companies: [
      /*all*/
    ],
  },

  { label: "Home insurance", value: "home", companies: [{ insuranceAgency: "5f106cc4be38834b48208d61", insuranceAgencyName: "Уника" }] },

  {
    label: "BG assist insurance",
    value: "bgAssist",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d5c", insuranceAgencyName: "Дженерали" },
      { insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" },
      { insuranceAgency: "5f106cc4be38834b48208d59", insuranceAgencyName: "БулИнс" },
    ],
  },
  {
    label: "EU assist insurance",
    value: "euAssist",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d5c", insuranceAgencyName: "Дженерали" },
      { insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" },
    ],
  },
  {
    label: "EU medicine insurance",
    value: "euMedicine",
    companies: [{ insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" }],
  },
  {
    label: "Car accident insurance",
    value: "carAccident",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d59", insuranceAgencyName: "БулИнс" },
      { insuranceAgency: "5f106cc4be38834b48208d5c", insuranceAgencyName: "Дженерали" },
    ],
  },
  { label: "Аccident insurance", value: "accident", companies: [{ insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" }] },
  {
    label: "Trip cancelation insurance",
    value: "cancelTravel",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d62", insuranceAgencyName: "Групама" },
      { insuranceAgency: "5f106cc4be38834b48208d5e", insuranceAgencyName: "Евроинс" },
    ],
  },
  {
    label: "Travel insurance",
    value: "travel",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d62", insuranceAgencyName: "Групама" },
      { insuranceAgency: "5f106cc4be38834b48208d5e", insuranceAgencyName: "Евроинс" },
      { insuranceAgency: "5f106cc4be38834b48208d61", insuranceAgencyName: "Уника" },
    ],
  },
  {
    label: "Medical insurance for foreigners",
    value: "medicineForeign",
    companies: [{ insuranceAgency: "5f106cc4be38834b48208d59", insuranceAgencyName: "БулИнс" }],
  },
  { label: "Firearm", value: "firearm", companies: [{ insuranceAgency: "5f106cc4be38834b48208d59", insuranceAgencyName: "БулИнс" }] },
];

const EditDiscount = ({ deactivateActiveDiscount, getCurrentDiscount, getInsuranceAgencies, editUpcomingDiscount, startLoading, stopLoading }) => {
  const discountID = window.location.href.split("/")[5];
  const [status, setStatus] = useState("");
  const [discountInfo, setDiscountInfo] = useState({
    type: "",
    insurances: [],
  });

  const [assignUsers, setAssignUsers] = useState({
    type: "",
    users: [],
  });
  const [removedUsers, setRemovedUsers] = useState([]);
  const firstRender = useRef(false);

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      startDate: "",
      endDate: null,
      discountCondition: "",
      discountCode: "",
      discountInPercent: false,
      genericDiscount: [],
      policyTypes: [],
      discount: null,
      customDiscounts: [],
      comment: "",
      assignUsers: [],
    },
  });

  useEffect(() => {
    if (!firstRender.current)
      getInsuranceAgencies({
        onSuccess: (res) => {
          res.map((agency) => {
            insurancesOptions[0].companies.push({
              insuranceAgency: agency._id,
              insuranceAgencyName: agency?.name,
              discount: null,
            });
            insurancesOptions[2].companies.push({
              insuranceAgency: agency._id,
              insuranceAgencyName: agency?.name,
              discount: null,
            });
          });
          getCurrentDiscount({
            _id: discountID,
            onSuccess: (res) => {
              setStatus(moment().isBetween(res.startDate, res.endDate) ? "Active" : res?.endDate ? moment().isBefore(res.endDate) && "Upcoming" : "Active");
              setValue("name", res.name);
              setValue("startDate", res.startDate);
              setValue("endDate", res.endDate);
              setValue("discountCondition", res.discountCondition);
              setValue("comment", res.comment);
              setValue("discountCode", res.discountCode);
              setValue("discountInPercent", res.discountInPercent);
              setValue("genericDiscount", res?.genericDiscount);
              setValue("policyTypes", res?.policyTypes);
              setValue("discount", res?.discount);
              setValue(
                "assignUsers",
                res?.attached?.map((user) => {
                  return {
                    fullName: user.fullName,
                    phoneNumber: user.phoneNumber,
                  };
                })
              );
              setValue(
                "customDiscounts",
                res?.customDiscounts?.map((disc) => {
                  return {
                    companies: disc?.customDiscountsPerInsuranceAgency.length ? disc?.customDiscountsPerInsuranceAgency : insurancesOptions.filter((ins) => ins.value === disc?.policyType)[0].companies,
                    insuranceType: disc?.policyType,
                    discount: disc?.discount,
                    general: disc?.genericDiscount,
                  };
                })
              );
              setDiscountInfo({
                type: res?.policyTypes?.length ? "custom" : "all",
              });
              setAssignUsers({
                type: "manually",
                users: res?.attached?.map((user) => {
                  return {
                    fullName: user.fullName,
                    phoneNumber: user.phoneNumber,
                  };
                }),
              });
            },
          });
        },
      });
    firstRender.current = true;
  }, []);

  const handleEdit = (e) => {
    let payload = {};

    if (e.genericDiscount) {
      payload = { ...e, policyTypes: [] };
      if (assignUsers.users.length) payload.assignUsers = assignUsers.users;
    } else {
      payload = {
        name: e.name,
        startDate: e.startDate,
        endDate: e.endDate,
        discountCondition: e.discountCondition,
        discountCode: e.discountCode,
        discountInPercent: e.discountInPercent || false,
        genericDiscount: e.genericDiscount,
        policyTypes: e.customDiscounts.map((ins) => ins.insuranceType),
        customDiscounts: e.customDiscounts.map((ins) => {
          return {
            policyType: ins.insuranceType,
            discount: ins.discount,
            genericDiscount: ins.general,
            customDiscountsPerInsuranceAgency: ins.companies.filter((comp) => comp.discount),
          };
        }),
        comment: e.comment,
      };
    }

    if (assignUsers.users.length) {
      const assignedUsers = assignUsers.users.filter((user) => !e.assignUsers.some((oldUser) => user.phoneNumber === oldUser.phoneNumber));
      payload.assignUsers = _.uniqBy(assignedUsers, "phoneNumber");
    }
    if (removedUsers.length) {
      const oldRemovedUsers = removedUsers?.filter((user) => _.flatMap(e?.assignUsers, "phoneNumber").includes(user?.phoneNumber));
      payload.removeUsers = _.uniqBy(oldRemovedUsers, "phoneNumber");
    }
    startLoading();
    editUpcomingDiscount({
      ...payload,
      _id: discountID,
      onSuccess: (res) => {
        stopLoading();
        document.getElementsByTagName("body")[0].style.overflow = "hidden hidden";
        toast.success("Discount code edited successfully!");
        history.push("/discounts");
      },
    });
  };

  // console.log(status);

  return (
    <div className="main-container" style={{ overflow: "auto" }}>
      <div className="inner-header-container flex-container space-between">
        <div className="left-part">
          <div className="close-icon" onClick={() => history.push("/discounts")} />
          <h2 className="inner-title">EDIT DISCOUNT</h2>
        </div>

        <Inputs.Button
          text="DEACTIVATE"
          deleted
          style={{ width: "8%", marginRight: "1%" }}
          onClick={() => {
            deactivateActiveDiscount({
              _id: discountID,
              onSuccess: (res) => {
                toast.success("Discount deactivated successfully!");
                history.push("/discounts");
              },
            });
          }}
        />
        <Inputs.Button
          text={"EDIT"}
          selected
          style={{ width: "7%", marginRight: "1%" }}
          onClick={handleSubmit((e) => {
            handleEdit(e);
          })}
        />
      </div>
      <GeneralInformation control={control} setValue={setValue} register={register} errors={errors} disabled={status === "Active" ? true : false} />
      {status === "Active" ? (
        <div className="body-container" style={{ height: "unset", marginTop: "1%" }}>
          <h2 className="inner-title" style={{ margin: "1% 0%" }}>
            Discount information
          </h2>
          <div className="flex-container full-width align-end">
            <div style={{ width: "15%" }}>
              <label
                style={{
                  width: "100%",
                  fontSize: "0.8rem",
                  margin: "10px 0px",
                }}
              >
                Discount for *
              </label>
              <p style={{ fontWeight: "bold" }}>{discountInfo?.type === "all" ? "All insurances" : "Custom"}</p>
            </div>
            <Inputs.TextInput label={"Discount"} compulsory number value={watch("discount")} suffix={<div className="flex-container justify-center align-center">{watch().discountInPercent ? "%" : "BGN"}</div>} disabled style={{ width: "10%", margin: "0% 1%" }} />
          </div>
        </div>
      ) : (
        <DiscountInformation control={control} setValue={setValue} register={register} errors={errors} watch={watch} setDiscountInfo={setDiscountInfo} discountInfo={discountInfo} insurancesOptions={insurancesOptions} />
      )}

      <AttachToUsers setData={setAssignUsers} disabled={false} data={assignUsers} removedData={removedUsers} setRemovedData={setRemovedUsers} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  discounts: state.discounts.discounts,
});
const mapDispatchToProps = (dispatch) => ({
  getCurrentDiscount: (payload) => dispatch(getCurrentDiscount(payload)),
  deactivateActiveDiscount: (payload) => dispatch(deactivateActiveDiscount(payload)),
  getInsuranceAgencies: (payload) => dispatch(getInsuranceAgencies(payload)),
  editUpcomingDiscount: (payload) => dispatch(editUpcomingDiscount(payload)),
  startLoading: (payload) => dispatch(startLoading(payload)),
  stopLoading: (payload) => dispatch(stopLoading(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditDiscount);
