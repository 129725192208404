/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { listReached, startLoading, stopLoading } from "../../actions";
import "./styles.scss";
import Scrollbars from "react-custom-scrollbars";
import Statistics from "../Statistics";
import { connect } from "react-redux";
import { Popover } from "@blueprintjs/core";
import { useLocation } from "react-router-dom";

const headerElements = [
  {
    name: "Name",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Email",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone number",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Age",
    query: { filter: "Time", options: [] },
  },
  {
    name: "OS",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Spend total",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Number of vehicles",
    query: { filter: "Role", options: [] },
  },
];

const ReachedUsersList = ({ listReached, reachedList, startLoading, stopLoading }) => {
  const query = JSON.parse(localStorage.getItem("adQuery") === "undefined" || localStorage.getItem("adQuery") === "null" ? null : localStorage.getItem("adQuery"));

  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  function replaceSecondChar(str) {
    let arr = str.split("");
    for (let i = 1; i < arr.length; i += 2) {
      arr[i] = "_";
    }
    return arr.join("");
  }

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && reachedList.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      listReached({
        query: { ...query, directMarketingAgreement: true },
        pageNumber: curPage,
        pageSize: 20,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    startLoading();
    listReached({
      query: { ...query, directMarketingAgreement: true },
      pageNumber: 1,
      pageSize: 20,
      onSuccess: (res) => stopLoading(),
    });
  }, []);

  return (
    <>
      <div className="main-container">
        <div className="inner-header-container">
          <div className="left-part" style={{ width: "92%" }}>
            <div className="close-icon" onClick={() => window.close()} />
            <h2 className="inner-title">TARGETED USERS</h2>
          </div>
        </div>
        <div
          className="body-container"
          style={{
            height: "92%",
            marginTop: "0%",
            overflowX: window.innerWidth < 1200 && "auto",
          }}
        >
          <Scrollbars
            style={{
              height: "99%",
              width: window.innerWidth < 1200 ? "180%" : "100%",
            }}
            onUpdate={handleUpdate}
            ref={tableRef}
            id={"scrollbar"}
            renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}
          >
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "12.5%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {reachedList?.docs?.map((doc, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "12.5%", fontSize: "13px" }}>
                    {replaceSecondChar(doc?.name) + "   " + replaceSecondChar(doc?.lastName)}
                  </div>
                  <div
                    className="row-data"
                    style={{
                      width: "12.5%",
                      fontSize: "13px",
                      wordBreak: "break-all",
                    }}
                  >
                    {doc?.user?.split("|")[0]}
                  </div>
                  <div className="row-data" style={{ width: "12.5%", fontSize: "13px" }}>
                    {doc?.user?.split("|")[1]}
                  </div>
                  <div
                    className="row-data"
                    style={{
                      width: "12.5%",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {doc?.ages?.[0] || "---"}
                  </div>

                  <div
                    className="row-data"
                    style={{
                      width: "12.5%",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {doc?.os}
                  </div>
                  <div
                    className="row-data"
                    style={{
                      width: "12.5%",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {doc?.spendTotal ? doc?.spendTotal + " BGN" : "---"}
                  </div>
                  <div className="row-data" style={{ width: "12.5%", fontSize: "13px" }}>
                    {doc?._vehicles?.length}
                    {doc?.carMakes?.length > 0 && (
                      <Popover
                        interactionKind="hover"
                        content={
                          <div className="car-makes-container">
                            <ul style={{ listStyleType: "circle" }}>
                              {doc?.carMakes?.map((car) => (
                                <li>{car || "---"}</li>
                              ))}
                            </ul>
                          </div>
                        }
                      >
                        <div className="info-icon black" style={{ marginLeft: "10px" }} />
                      </Popover>
                    )}
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  reachedList: state.ads.reachedList,
  query: state.ads.query,
});
const mapDispatchToProps = (dispatch) => ({
  listReached: (payload) => dispatch(listReached(payload)),
  startLoading: (payload) => dispatch(startLoading(payload)),
  stopLoading: (payload) => dispatch(stopLoading(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ReachedUsersList);
