/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getStatisticsSuccess, getAppSessions } from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import moment from "moment";
import { options } from "../../../config/constants";
import Inputs from "../../Inputs";
import { splitPeriods } from "../../../utilites/HelperFunctions";
import { toast } from "react-toastify";

const Sessions = ({ getStatisticsSuccess, getAppSessions, sessions }) => {
  const [selPeriod, setSelPeriod] = useState({
    startDate: options[0].value,
    endDate: moment().endOf("day").toISOString(),
  });

  const [filtered, setFiltered] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);

  useEffect(() => {
    getStatisticsSuccess({ type: "sessions", loading: true });
    getAppSessions({
      startDate: options[0].value,
      endDate: moment().endOf("day").toISOString(),
    });
  }, []);

  useEffect(() => {
    if (filtered) {
      setInnerLoading(true);
      if (!selPeriod.startDate) {
        var monthsDiff = moment().diff("2020-07-21T15:36:15.133+0000", "months");
        const periods = splitPeriods(monthsDiff);

        const promiseArray = periods.map((period) => {
          return new Promise((resolve, reject) => {
            getAppSessions({
              ...period,
              merge: true,
              onSuccess: () => resolve(),
              onError: (payload) => {
                resolve();
                toast.error(`Period: ${moment(payload.startDate).format("DD.MM.YYYY")} - ${moment(payload.endDate).format("DD.MM.YYYY")} couldn't be obtained.`);
              },
            });
          });
        });

        Promise.all(promiseArray)
          .then((res) => {
            setInnerLoading(false);
          })
          .catch((err) => {
            toast.error(err);
          });
      } else
        getAppSessions({
          ...selPeriod,
          onSuccess: (res) => setInnerLoading(false),
        });
    }
  }, [selPeriod]);

  const retrieveInfo = (field) => {
    var infoArray = new Array();

    Object.keys(sessions?.[field]).map((key) => {
      var sum = 0;
      sessions?.data?.map((period) => {
        if (period[field]?.[key]) sum += period[field]?.[key];
      });
      infoArray.push({
        value: key?.includes("Less") ? "< 1 minute" : key,
        label: sum,
      });
    });

    return infoArray.sort((a, b) => b.label - a.label);
  };

  return (
    <>
      <div className="statistics-sessions-container col" style={{ maxHeight: "88%" }}>
        {innerLoading ? (
          <Statistics.LoaderInline />
        ) : (
          <>
            <div className="border-container" style={{ height: "unset" }}>
              <div style={{ display: "flex", alignItems: "center", width: "99%" }}>
                <div className="indicator-circle gray" />
                <div style={{ width: "95%" }}>
                  <h2 className="statistics-info-box-text" style={{ width: "100%" }}>
                    {sessions?.data
                      ? sessions?.data?.reduce((ac, obj) => {
                          return ac + obj.total;
                        }, 0)
                      : sessions?.total || "---"}{" "}
                    TOTAL IN APP SESSIONS
                  </h2>
                  <p style={{ fontSize: "13px" }}>1 session = staying in for min 5 sec</p>
                </div>
              </div>
              <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} startField={"startDate"} endField={"endDate"} />
            </div>
            <div style={{ display: "flex" }}>
              <div className="border-container background" style={{ marginRight: "1%" }}>
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <div className="indicator-circle dark-gray" />
                  <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "16px" }}>
                    <b style={{ fontSize: "19px" }}>
                      {sessions?.data
                        ? sessions?.data?.reduce((ac, obj) => {
                            return ac + obj.unique;
                          }, 0)
                        : sessions?.unique || "0"}
                    </b>{" "}
                    UNIQUE ACTIVE USERS
                  </h2>
                </div>
              </div>
              <div className="border-container background">
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <div className="indicator-circle dark-gray" />
                  <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "16px" }}>
                    <b style={{ fontSize: "19px" }}>
                      {sessions?.data
                        ? sessions?.data
                            ?.reduce((ac, obj) => {
                              return ac + obj.avg;
                            }, 0)
                            .toFixed(2)
                        : sessions?.avg || "0"}
                    </b>{" "}
                    AVERAGE USER SESIONS
                  </h2>
                </div>
              </div>
            </div>
            <div className="statistics-sessions-content">
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>SESSIONS LENGTH</h4>
                {sessions.loading ? (
                  <Statistics.LoaderInline />
                ) : (
                  <>
                    <Statistics.Horizontal
                      data={
                        sessions?.byDuration
                          ? sessions?.data
                            ? retrieveInfo("byDuration")
                            : Object.keys(sessions?.byDuration).map((key) => {
                                return {
                                  value: key?.includes("Less") ? "< 1 minute" : key,
                                  label: sessions?.byDuration[key],
                                };
                              })
                          : null
                      }
                    />
                  </>
                )}
                <div className="suffix-text">
                  Sessions per user group based on total of{" "}
                  <b style={{ marginLeft: "5px" }}>
                    {" "}
                    {sessions?.data
                      ? sessions?.data?.reduce((ac, obj) => {
                          return ac + obj.total;
                        }, 0)
                      : sessions?.total}
                  </b>
                </div>
              </div>
            </div>
            <div className="statistics-sessions-content">
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>TIMES OPENED</h4>
                {sessions.loading ? (
                  <Statistics.LoaderInline />
                ) : (
                  <>
                    <Statistics.Horizontal
                      data={
                        sessions?.timesOpened
                          ? sessions?.data
                            ? retrieveInfo("timesOpened")
                            : Object.keys(sessions?.timesOpened).map((key) => {
                                return {
                                  value: key,
                                  label: sessions?.timesOpened[key],
                                };
                              })
                          : null
                      }
                    />
                  </>
                )}
                <div className="suffix-text">
                  Sessions per user group based on total of{" "}
                  <b style={{ marginLeft: "5px" }}>
                    {" "}
                    {sessions?.data
                      ? sessions?.data?.reduce((ac, obj) => {
                          return ac + obj.total;
                        }, 0)
                      : sessions?.total}
                  </b>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sessions: state.dashboard.sessions,
});
const mapDispatchToProps = (dispatch) => ({
  getAppSessions: (payload) => dispatch(getAppSessions(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
