/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { Category } from "./Category";
import { emit, useQuery } from "../../utilites/helpers";
import { useSelector } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { isEmpty } from "lodash";
import _ from "lodash";
import Inputs from "../Inputs";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Modals from "../../components/ModalComponent/Modals";

export default function TasksComponent() {
  const tasks = useSelector((state) => state.tasks.tasks);
  const filters = useSelector((state) => state.tasks.filters);
  const lastOpenedTask = useSelector((state) => state.tasks.lastOpenedTask);
  const socket = useSelector(({ tasks }) => tasks?.taskSocket) || null;
  const { isLimited } = useSelector(({ login }) => login);
  const scrollRef = useRef();

  const scrollRefHorizontal = useRef();

  const [isActive, setIsActive] = useState(false);
  const [input, setInput] = useState("");

  const { _id } = useQuery();
  const [columns, setColumns] = useState([]);
  const [task, setTask] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
  const [editModal, setEditModal] = useState({ open: false, id: null });
  // const [filters, setFilters] = useState({})

  const handleCreateColumn = () => {
    emit(socket, { action: "task/createCategory", payload: { title: input } });
    setIsActive(!isActive);
    setInput("");
  };

  const reorder = (list, start, end) => {
    const reordered = [...list];
    const [removed] = reordered.splice(start, 1);
    reordered.splice(end, 0, removed);
    return reordered;
  };

  const handleColumnsPositioning = ({ type, source, destination, ...r }) => {
    if (!destination) return;
    if (source.droppableId === destination.droppableId && source.index === destination.index) return;
    if (type === "COLUMN") {
      const reordered = reorder(columns, source.index, destination.index);
      setColumns(reordered);
      emit(socket, { action: "task/reorderCategories", payload: { newOrder: reordered.map((c) => c._id) } });
      return;
    } else if (type === "TASKS") {
      if (destination.droppableId === source.droppableId) {
        const category = columns.find((c) => c._id === source.droppableId);
        let newTasks = category._tasks.map((task) => task._id);
        const reordered = reorder(newTasks, source.index, destination.index);
        newTasks = reorder(category._tasks, source.index, destination.index);
        let newCategories = [...columns];
        let categoryIndex = columns.findIndex((el) => el._id === category._id);

        newCategories.splice(categoryIndex, 1);
        newCategories.splice(categoryIndex, 0, { ...category, _tasks: newTasks });

        emit(socket, { action: "task/reorderTasks", payload: { categoryId: source.droppableId, newOrder: reordered } });
        setColumns(newCategories);
      } else if (destination.droppableId !== source.droppableId) {
        //moving to different list
        const category = columns.find((c) => c._id === source.droppableId);
        const categoryDrag = columns.find((c) => c._id === destination.droppableId);
        const task = category._tasks.find((el) => el._id === category._tasks[source.index]._id); //moved task

        const oldTasks = category._tasks; //tasks in source category
        oldTasks.splice(source.index, 1);

        const newTasks = categoryDrag._tasks; //tasks in destination category
        newTasks.splice(destination.index, 0, task).map((task) => task._id);

        const ordered = newTasks.map((task) => task._id);

        emit(socket, {
          action: "task/changeTaskCategory",
          payload: { oldCategoryId: source.droppableId, newCategoryId: destination.droppableId, taskId: task._id, newOrder: ordered },
        });
      }
    }
  };

  const props = { socket, task, setTask, setShowFilter };

  const delayedScrolling = _.debounce(() => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft } = scrollRefHorizontal.current;
    // scrollRefHorizontal.current.scrollLeft += 100
    // dispatch(setHorizontalScroll(scrollLeft));
  }, 300);

  useEffect(() => {
    if (!socket) return;
    else if (isEmpty(columns)) {
      if (Object.values(filters || {}).length) {
        emit(socket, { action: "task/getTasks", payload: { filters: filters } });
      } else {
        emit(socket, { action: "task/getTasks", payload: {} });
      }
    }
  }, [socket, _id]);

  useEffect(() => {
    if (tasks) setColumns(tasks);
  }, [tasks]);

  return (
    <div className="tasks-inner-wrapper" ref={scrollRefHorizontal} onScroll={delayedScrolling}>
      {columns.length ? (
        <DragDropContext onDragEnd={handleColumnsPositioning} style={{ overflow: "auto" }}>
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {(provided) => (
              <div className="row-droppable" ref={provided.innerRef} {...provided.droppableProps}>
                {columns?.map((column, i) => {
                  if (lastOpenedTask?._id === column?._id) {
                    return <Category category={column} scrollRef={scrollRef} setDeleteModal={setDeleteModal} setEditModal={setEditModal} key={column?._id} index={i} settings={true} {...props} />;
                  } else {
                    return <Category category={column} key={column?._id} setDeleteModal={setDeleteModal} setEditModal={setEditModal} index={i} settings={true} {...props} />;
                  }
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <></>
      )}
      {!isLimited && (
        <div className="category-container add-category">
          {isActive ? (
            <div className="add-category-title-wrapper">
              <label>Заглавие</label>
              <input type="text" onChange={(e) => setInput(e.target.value)} value={input}></input>

              <div className="change-category-title-btn-wrapper">
                <Inputs.Button text="Откажи" style={{ height: "45px" }} deleted onClick={() => setIsActive(!isActive)} />
                <Inputs.Button text="Добави" style={{ height: "45px" }} disabled={input ? false : true} selected onClick={handleCreateColumn} />
              </div>
            </div>
          ) : (
            <>
              {!isLimited && (
                <button className="dashed-btn" onClick={() => setIsActive(!isActive)}>
                  Добави категория
                </button>
              )}
            </>
          )}
        </div>
      )}

      <ModalComponent
        open={deleteModal.open}
        children={
          <Modals.DeleteModal
            header="Изтриване на колона"
            body={"Сигурни ли сте, че искате да изтриете този елемент?"}
            handleClose={() =>
              setDeleteModal({
                open: false,
                id: "",
              })
            }
            handleSubmit={() => {
              emit(socket, { action: "task/deleteCategory", payload: { categoryId: deleteModal?.id } });
            }}
          />
        }
      />
      <ModalComponent
        open={editModal.open}
        children={
          <Modals.EditModal
            id={editModal.id}
            handleClose={() =>
              setEditModal({
                open: false,
                id: "",
              })
            }
          />
        }
      />
    </div>
  );
}
