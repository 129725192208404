import React, { useState } from "react";
import Popup from "reactjs-popup";
import { monthOptions, options } from "../../../config/constants";
import Inputs from "../../Inputs";
import moment from "moment";
import _ from "lodash";

const SelectPeriod = ({ selPeriod, setSelPeriod, setFiltered, tab, growth, selected, startField, endField, noCustom, setFilteredTab }) => {
  const [customFields, setCustomFields] = useState(false);

  const handleTextSelector = () => {
    if (growth) {
      const foundMonth = monthOptions.find((opt) => opt[startField] === selPeriod[startField]);
      return foundMonth ? foundMonth.label : "All";
    } else {
      const foundPeriod = options.find((opt) => (tab && opt.value === selPeriod?.[tab]?.[startField]) || opt.value === selPeriod[startField]);

      if (tab === "fines") console.log(selPeriod?.[tab]);
      return foundPeriod ? foundPeriod.label : ["Custom", "custom"].includes(selPeriod?.[tab]?.label || selPeriod?.label) ? "Custom" : "All";
    }
  };

  const periodOnChange = (period, close) => {
    if (growth) {
      setSelPeriod({
        [startField]: period[startField] === null ? null : period[startField],
        [endField]: period?.[endField] === null ? null : period?.[endField],
      });
      setFiltered(true);
      close();
    } else {
      if (period === "custom") {
        setFiltered(false);
        setCustomFields(true);
        if (tab) setSelPeriod({ ...selPeriod, [tab]: { ...selPeriod[tab], label: "Custom" } });
        else setSelPeriod({ ...selPeriod, label: "Custom" });
      } else {
        if (tab) {
          setSelPeriod({
            ...selPeriod,
            [tab]: {
              [startField]: period?.value === null ? null : period,
              [endField]: moment().endOf("day").toISOString(),
              label: period.label,
              changed: true,
            },
          });
          setFilteredTab(tab);
        } else
          setSelPeriod({
            ...selPeriod,
            [startField]: period?.value === null ? null : period,
            label: period.label,
          });
        setFiltered(true);
        close();
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <p className="period-text">{handleTextSelector()}</p>
      <Popup
        className="popup-header-options-container"
        onOpen={() => {
          if (tab) {
            if (selPeriod?.[tab]?.label && ["Custom", "custom"].includes(selPeriod?.[tab]?.label) && !growth) {
              setFiltered(false);
              setCustomFields(true);
            }
          }
          else if (["Custom", "custom"].includes(selPeriod?.label) && !growth) {
            setFiltered(false);
            setCustomFields(true);
          }
        }}
        trigger={<div className={`settings-icon ${selected && "selected"}`} />}
        position="bottom right"
        contentStyle={{ padding: "10px", width: 240 }}
      >
        {(close) => (
          <>
            <Inputs.RadioButtons
              options={growth ? monthOptions : noCustom ? _.concat(_.slice(options, 0, options.length - 2), _.last(options)) : options}
              column
              value={tab ? (selPeriod?.[tab]?.label ? "custom" : selPeriod?.[tab]?.[startField]) : selPeriod?.label && selPeriod?.label === "Custom" ? "custom" : selPeriod[startField]}
              onChange={(period) => periodOnChange(period, close)}
            />
            {customFields && (
              <>
                <Inputs.DatePicker
                  label={"Start date"}
                  style={{ width: "220px" }}
                  maxDate={moment()._d}
                  value={tab ? (selPeriod[tab][startField] ? moment(selPeriod[tab][startField])?._d : moment()._d) : selPeriod[startField] ? moment(selPeriod[startField])?._d : moment()._d}
                  onChange={(e) => {
                    if (tab) setSelPeriod({ ...selPeriod, [tab]: { ...selPeriod[tab], [startField]: moment(e).startOf("day").toISOString(), label: "Custom" } });
                    else setSelPeriod({ ...selPeriod, [startField]: moment(e).startOf("day").toISOString() });
                  }}
                />
                <Inputs.DatePicker
                  label={"End date"}
                  style={{ width: "220px", marginTop: "10px" }}
                  maxDate={moment()._d}
                  value={tab ? (selPeriod[tab][endField] ? moment(selPeriod[tab][endField])?._d : moment()._d) : selPeriod[endField] ? moment(selPeriod[endField])?._d : moment()._d}
                  onChange={(e) => {
                    if (tab) setSelPeriod({ ...selPeriod, [tab]: { ...selPeriod[tab], [endField]: moment(e).endOf("day").toISOString(), label: "Custom" } });
                    else setSelPeriod({ ...selPeriod, [endField]: moment(e).endOf("day").toISOString() });
                  }}
                />

                <Inputs.Button
                  text={"SAVE"}
                  selected
                  style={{ width: "220px", marginTop: "10px" }}
                  onClick={() => {
                    if (tab) {
                      setSelPeriod({ ...selPeriod, [tab]: { ...selPeriod[tab], changed: true, label: "Custom" } });
                      setFilteredTab(tab);
                    } else setSelPeriod({ ...selPeriod, label: "Custom" });
                    setFiltered(true);
                    close();
                  }}
                />
              </>
            )}
          </>
        )}
      </Popup>
    </div>
  );
};

export default SelectPeriod;
