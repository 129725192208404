import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { withRouter, Redirect } from "react-router-dom";
import Dashboard from "../../screens/Dashboard/Dashboard";
import { App } from "../../screens/App";
import { User } from "../../utilites/User";
import Header from "../Header/Header";
import Website from "../../screens/Website/Website";
import { ArticleForm } from "../ArticleForm";
import OfficeForm from "../OfficeForm/OfficeForm";
import { Finances } from "../../screens/Finances";
import { Login } from "../../screens/Login";
import { Ads, CurrentAd } from "../../screens/Ads";
import { AdForm, ReachedUsersList } from "../AdForm";
import ActiveUsersList from "../Statistics/Sessions/ActiveUsersList";
import { AddDiscount, Discounts } from "../../screens/Discounts";
import EditDiscount from "../../screens/Discounts/DiscountOperations/EditDiscount";
import Tasks from "../../screens/Tasks/Tasks";
import AddEditTask from "../../screens/Tasks/AddEditTask/AddEditTask";
import ReviewTask from "../../screens/Tasks/ReviewTask/ReviewTask";
import LoaderGlobal from "../LoaderGlobal";
import { usersWithDisabledTasks } from "../../config/constants";

const AuthRoute = ({ children, ...rest }) => {
  return <Route render={({ location }) => (!User.isAuthenticated ? <Route {...rest} /> : <Redirect to={{ pathname: "/", state: { from: location } }} />)} />;
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) => {
        return User.isAuthenticated ? <Route {...rest} /> : <Redirect to={{ pathname: "/login", state: { from: location } }} />;
      }}
    />
  );
};

const AdminRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) => {
        return User.isAdmin ? (
          <Route {...rest} />
        ) : !User.isAuthenticated ? (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        ) : (
          <Redirect to={{ pathname: "/advertisers", state: { from: location } }} />
        );
      }}
    />
  );
};

// const TaskRoute = ({ children, ...rest }) => {
//   return (
//     <Route
//       render={({ location }) => {
//         return User.isAdmin && !usersWithDisabledTasks.includes(User.getPhoneNumber()) ? (
//           <Route {...rest} />
//         ) : !User.isAuthenticated ? (
//           <Redirect to={{ pathname: "/login", state: { from: location } }} />
//         ) : (
//           <Redirect to={{ pathname: "/advertisers", state: { from: location } }} />
//         );
//       }}
//     />
//   );
// }

class Routes extends Component {
  render() {
    return (
      <>
        {User.isAuthenticated && <Header />}
        <div className="dashboard-wrap">
          <Switch>
            <AuthRoute exact path="/login" component={Login} />
            <PrivateRoute exact path="/advertisers" component={Ads} />
            <PrivateRoute exact path="/advertisers/create-campaign" component={AdForm} />
            <PrivateRoute exact path="/advertisers/reached-users" component={ReachedUsersList} />
            <PrivateRoute path="/advertisers/campaign" component={CurrentAd} />
            <PrivateRoute path="/advertisers/edit/:adId" component={AdForm} />
            <AdminRoute exact path="/" component={Dashboard} />
            <AdminRoute exact path="/active-users" component={ActiveUsersList} />
            <AdminRoute exact path="/app" component={App} />
            <AdminRoute exact path="/website" component={Website} />
            <AdminRoute exact path="/articles/add" component={ArticleForm} />
            <AdminRoute path="/article/edit" component={ArticleForm} />
            <AdminRoute exact path="/office/add" component={OfficeForm} />
            <AdminRoute path="/office/edit" component={OfficeForm} />
            <AdminRoute path="/finances" component={Finances} />
            <AdminRoute path="/discounts" component={Discounts} />
            <AdminRoute path="/discount/add" component={AddDiscount} />
            <AdminRoute path="/discount/edit" component={EditDiscount} />
            <AdminRoute path="/tasks" component={Tasks} />
            <AdminRoute exact path="/task" component={AddEditTask} />
            <AdminRoute exact path="/task/:_id" component={AddEditTask} />
            <AdminRoute exact path="/task/new/:categoryId" component={AddEditTask} />
            <AdminRoute exact path="/review-task" component={ReviewTask} />
            <AdminRoute exact path="/review-task/:_id" component={ReviewTask} />
          </Switch>
        </div>
        <LoaderGlobal />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading };
};

export default withRouter(connect(mapStateToProps)(Routes));
