/* eslint-disable */
import React, { useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { history } from "../../../config/stores";
import "../styles.scss";

const headerElements = [
  {
    name: "City",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Address",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Phone",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Email",
    query: { filter: "Role", options: [] },
  },
];

const OfficesGrid = ({ offices }) => {
  return (
    <Scrollbars style={{ height: "95.5%", width: "100%", marginTop: "1%" }} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
      <div className="table-header">
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{
                width: i === 1 ? "17%" : "12%",
              }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {offices?.map((office, i) => {
        return (
          <div className="table-body-row" key={i}>
            <div className="row-data" style={{ width: "12%" }} onClick={() => history.push(`/office/edit/${office?._id}`)}>
              {office?.name || "---"}
            </div>
            <div className="row-data" style={{ width: "17%" }}>
              {office?.address || "---"}
            </div>
            <div className="row-data" style={{ width: "12%" }}>
              {office?.phoneNumber || "---"}
            </div>
            <div className="row-data" style={{ width: "59%", justifyContent: "space-between" }}>
              {office?.email || "---"}
              <div className="edit-icon" style={{ width: "40px", height: "40px",marginRight:"10px" }} onClick={() => history.push(`/office/edit/${office?._id}`)}/>
            </div>
          </div>
        );
      })}
    </Scrollbars>
  );
};

export default OfficesGrid;
