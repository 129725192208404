import moment from "moment";
export const options = [
  {
    label: "Last 24 hours",
    value: moment().subtract(1, "days").startOf("day").toISOString(),
  },
  {
    label: "Last 7 days",
    value: moment().subtract(7, "days").startOf("day").toISOString(),
  },
  {
    label: "Last 31 days",
    value: moment().subtract(31, "days").startOf("day").toISOString(),
  },
  {
    label: "Last 6 months",
    value: moment().subtract(6, "months").startOf("day").toISOString(),
  },
  {
    label: "Last 12 months",
    value: moment().subtract(1, "year").startOf("day").toISOString(),
  },
  {
    label: "Custom",
    value: "custom",
  },
  {
    label: "All",
    value: null,
  },
];
export const monthOptions = [
  {
    label: "January",
    startDate: moment().startOf("year").toISOString(),
    endDate: moment().startOf("year").endOf("month").toISOString(),
  },
  {
    label: "February",
    startDate: moment().startOf("year").add("1", "month").toISOString(),
    endDate: moment().startOf("year").add("1", "month").endOf("month").toISOString(),
  },
  {
    label: "March",
    startDate: moment().startOf("year").add("2", "month").toISOString(),
    endDate: moment().startOf("year").add("2", "month").endOf("month").toISOString(),
  },
  {
    label: "April",
    startDate: moment().startOf("year").add("3", "month").toISOString(),
    endDate: moment().startOf("year").add("3", "month").endOf("month").toISOString(),
  },
  {
    label: "May",
    startDate: moment().startOf("year").add("4", "month").toISOString(),
    endDate: moment().startOf("year").add("4", "month").endOf("month").toISOString(),
  },
  {
    label: "June",
    startDate: moment().startOf("year").add("5", "month").toISOString(),
    endDate: moment().startOf("year").add("5", "month").endOf("month").toISOString(),
  },
  {
    label: "July",
    startDate: moment().startOf("year").add("6", "month").toISOString(),
    endDate: moment().startOf("year").add("6", "month").endOf("month").toISOString(),
  },
  {
    label: "August",
    startDate: moment().startOf("year").add("7", "month").toISOString(),
    endDate: moment().startOf("year").add("7", "month").endOf("month").toISOString(),
  },
  {
    label: "September",
    startDate: moment().startOf("year").add("8", "month").toISOString(),
    endDate: moment().startOf("year").add("8", "month").endOf("month").toISOString(),
  },
  {
    label: "October",
    startDate: moment().startOf("year").add("9", "month").toISOString(),
    endDate: moment().startOf("year").add("9", "month").endOf("month").toISOString(),
  },
  {
    label: "November",
    startDate: moment().startOf("year").add("10", "month").toISOString(),
    endDate: moment().startOf("year").add("10", "month").endOf("month").toISOString(),
  },
  {
    label: "December",
    startDate: moment().startOf("year").add("11", "month").toISOString(),
    endDate: moment().startOf("year").add("11", "month").endOf("month").toISOString(),
  },
];

export const editorConfig = {
  toolbar: [
    { name: "corrections", items: ["Undo", "Redo"] },
    { name: "basicstyles", items: ["Bold", "Italic", "Underline", "Strike"] },
    { name: "styles", items: ["Format"] },
    { name: "paragraph", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock", "-", "NumberedList", "BulletedList"] },
    { name: "clipboard", items: ["Cut", "Copy", "Paste", "PasteText"] },
    { name: "tools", items: ["Maximize"] },
  ],
  enterMode: "ENTER_BR",
  plugins: ["basicstyles", "clipboard", "emoji", "enterkey", "entities", "floatingspace", "indentlist", "justify", "link", "list", "toolbar", "undo", "wysiwygarea", "maximize", "format"],
};

export const editorNotificationsConfig = {
  enterMode: "ENTER_BR",
  toolbar: [{ name: "links", items: ["Link", "Unlink"] }],
  plugins: ["link", "toolbar", "undo", "wysiwygarea"],
  uiColor: "#FFFFFF",
  height: "100px",
};

export const usersWithDisabledTasks = ["+359897860255", "+359884244989"];
