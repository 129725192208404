import React, { useState } from "react";
import { requestCode, activateCode, logIn, tasksSocketConnect } from "../../actions";
import LoginVerification from "../../components/LoginVerification";
import { connect } from "react-redux";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";
import { toast } from "react-toastify";
import { useEventListener } from "../../utilites/useEventListener";
import { generateLoginToken } from "../../utilites/helpers";
import "./styles.scss";

const Login = ({ requestCode, activateCode, logIn, tasksSocketConnect }) => {
  const [showCode, setShowCode] = useState(false);
  const [code, setCode] = useState(new Array(4).fill(""));
  // const [data, setData] = useState({ phoneNumber: "+359889335580" }); // admin -> DEV
  // const [data, setData] = useState({ phoneNumber: "+359884840187" }); // advertiser -> DEV
  const [data, setData] = useState({ phoneNumber: "+359" });
  const [failed, setFailed] = useState(null);

  const handleChange = (field, { target: { value } }) => setData({ ...data, [field]: value });

  const handleRequestCode = async () => {
    const token = await generateLoginToken();
    requestCode({
      phoneNumber: data.phoneNumber,
      token: token,
      webLogin: true,
      action: "requestCode",
      onSuccess: (res) => setShowCode(true),
    });
  };

  const handleLogin = async () => {
    const token = await generateLoginToken();
    activateCode({
      phoneNumber: data.phoneNumber,
      webLogin: true,
      token: token,
      action: "activateCode",
      code: code.join(""),
      onSuccess: async (res) => {
        const newToken = await generateLoginToken();
        logIn({
          phoneNumber: data.phoneNumber,
          webLogin: true,
          token: newToken,
          action: "login",
          password: code.join(""),
          onSuccess: (res) => {
            toast.success("Login successfully!");
            if (res?.payload?.allowedTaskCategories?.length) {
              history.push("/tasks");
              return;
            }
            if (res.payload.adminRole === "admin") history.push("/");
            else history.push("/advertisers");
          },
        });
      },

      onError: () => setFailed(true),
    });
  };

  useEventListener("keydown", ({ key }) => {
    if (key !== "Enter") return;
    if (showCode && code.join("").length === 4 && !failed) handleLogin();
    else if (!showCode && data.phoneNumber.includes("+359") && data.phoneNumber.length === 13) handleRequestCode();
  });

  return (
    <div className="login-container">
      <div className="login-inner-container flex-container">
        <div className="login-left-container">
          <div className="login-left-inner-container col">
            <div className="logo" />
            <div className="text-conatiner">
              <p className="header">{showCode ? "Верификация на телефона" : "Добре дошли!"}</p>
              <p style={{ margin: "2% 0%" }}>{showCode ? "Моля въведете четирицифрения код, който Ви изпратихме на телефон:" : "Моля въведете телефонния номер, с който сте регистрирани"}</p>
            </div>
            <div className="login-form col" style={{ padding: window.innerWidth < 1200 && "0% 10%" }}>
              <Inputs.TextInput placeholder="Телефонен номер" value={data?.phoneNumber || ""} onChange={(e) => handleChange("phoneNumber", e)} disabled={showCode} />
              {showCode ? (
                <>
                  <LoginVerification code={code} setCode={(value) => setCode(value)} failed={failed} setFailed={setFailed} />
                  <Inputs.Button text="Вход" selected style={{ marginBottom: "5%" }} onClick={handleLogin} disabled={code.includes("")} />
                  <Inputs.Button text="Редактирай номер" onClick={() => setShowCode(false)} />
                </>
              ) : (
                <Inputs.Button text="Продължи" onClick={handleRequestCode} selected style={{ margin: "5% 0%" }} disabled={!data?.phoneNumber.includes("+359") || data?.phoneNumber.length !== 13} />
              )}
            </div>
          </div>
        </div>
        <div className="login-right-container" />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  requestCode: (payload) => dispatch(requestCode(payload)),
  activateCode: (payload) => dispatch(activateCode(payload)),
  logIn: (payload) => dispatch(logIn(payload)),
  tasksSocketConnect: (payload) => dispatch(tasksSocketConnect(payload)),
});
export default connect(null, mapDispatchToProps)(Login);
