/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { adsStats, StarterTypes } from "../actions";
import { API_BOS_URL, API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { toast } from "react-toastify";

export const getAds = (action$) =>
  action$.pipe(
    ofType(adsStats.GET_ADS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/campaign/browse`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: adsStats.GET_ADS_SUCCESS,
              payload: response.payload,
            });
            if (payload.onSuccess) payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.message);
            obs.next({
              type: StarterTypes.STOP_LOADING,
              payload: {},
            });
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getAllFiltersEpic = (action$) =>
  action$.pipe(
    ofType(adsStats.GET_ALL_FILTERS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/campaign/filters`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: adsStats.GET_ALL_FILTERS_SUCCESS,
              payload: response.payload,
            });

            if (payload?.onSuccess) payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.message);
            obs.next({
              type: StarterTypes.STOP_LOADING,
              payload: {},
            });
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const checkReachedEpic = (action$) =>
  action$.pipe(
    ofType(adsStats.CHECK_REACHED),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/campaign/checkReach`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload.onSuccess) payload.onSuccess(response.payload);
            obs.next({
              type: StarterTypes.STOP_LOADING,
              payload: {},
            });
            obs.next({
              type: adsStats.CHECK_REACHED_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.message);
            obs.next({
              type: StarterTypes.STOP_LOADING,
              payload: {},
            });
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const listReachedEpic = (action$) =>
  action$.pipe(
    ofType(adsStats.LIST_REACHED),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/campaign/listReachUsers`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: adsStats.LIST_REACHED_SUCCESS,
              payload: response.payload,
            });
            payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.message);
            obs.next({
              type: StarterTypes.STOP_LOADING,
              payload: {},
            });
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const findUserByPhoneEpic = (action$) =>
  action$.pipe(
    ofType(adsStats.FIND_USER_BY_PHONE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/user/findByPhone`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.message);
            obs.next({
              type: StarterTypes.STOP_LOADING,
              payload: {},
            });
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const createAd = (action$) =>
  action$.pipe(
    ofType(adsStats.CREATE_AD),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/campaign`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: adsStats.GET_ADS_SUCCESS,
              payload: response.payload,
              key: "ads",
            });
            payload.onSuccess();
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.message);
            obs.next({
              type: StarterTypes.STOP_LOADING,
              payload: {},
            });
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getCurrentAdEpic = (action$) =>
  action$.pipe(
    ofType(adsStats.GET_CURRENT_AD),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/campaign/show`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: adsStats.GET_CURRENT_AD_SUCCESS,
              payload: response.payload,
              key: "currentAd",
            });
            payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const editAdEpic = (action$) =>
  action$.pipe(
    ofType(adsStats.EDIT_AD),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/campaign`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            toast.error(err?.response?.message);
            obs.next({
              type: StarterTypes.STOP_LOADING,
              payload: {},
            });
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
