/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getStatisticsSuccess, getIncomeRevenue, getRegisteredUsers, getInnerLicenses, getInnerVehicles } from "../../../actions";
import "./styles.scss";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { options } from "../../../config/constants";
import { splitPeriods } from "../../../utilites/HelperFunctions";
import { toast } from "react-toastify";

const horizontalObjects = [
  {
    head: "COMPLETED TRANSACTIONS",
    valueKey: "byTransactions",
  },
  {
    head: "AMOUNT SPENT",
    valueKey: "byAmountSpent",
  },
];

const osContainers = [
  {
    field: "ios",
    text: "IOS",
  },
  {
    field: "android",
    text: "ANDROID",
  },
  {
    field: "unknown",
    text: "UNKNOWN",
  },
];

const genderContainers = [
  {
    field: "male",
    text: "MEN",
  },
  {
    field: "female",
    text: "WOMEN",
  },
  {
    field: "unknown",
    text: "UNKNOWN",
  },
];

const yearContainer = ["<=24", "25-34", "35-44", "45-64", ">=65"];

const RegisteredUsers = ({ getStatisticsSuccess, getIncomeRevenue, incomes, getRegisteredUsers, registeredUsers, getInnerLicenses, getInnerVehicles, innerLicenses, innerVehicles }) => {
  const [selPeriod, setSelPeriod] = useState({
    startDate: options[6].value,
    endDate: moment().endOf("day").toISOString(),
  });

  const [filtered, setFiltered] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);

  useEffect(() => {
    getStatisticsSuccess({ type: "registeredUsers", loading: true });
    getStatisticsSuccess({ type: "innerVehicles", loading: true });
    getStatisticsSuccess({ type: "innerLicenses", loading: true });
    getRegisteredUsers({
      startDate: options[0].value,
      endDate: moment().endOf("day").toISOString(),
      returnCached: true,
    });
    getInnerLicenses({
      startDate: null,
      endDate: moment().endOf("day").toISOString(),
    });
    getInnerVehicles({
      startDate: null,
      endDate: moment().endOf("day").toISOString(),
    });
  }, []);

  useEffect(() => {
    if (filtered) {
      setInnerLoading(true);
      if (!selPeriod.startDate) {
        getIncomeRevenue({ ...selPeriod });
        getInnerLicenses({ ...selPeriod });
        getInnerVehicles({
          ...selPeriod,
          onSuccess: () => {
            setInnerLoading(false);
          },
        });
        getRegisteredUsers({
          ...selPeriod,
          returnCached: true,
        });
      } else {
        getIncomeRevenue({ ...selPeriod });
        getRegisteredUsers({
          ...selPeriod,
          onSuccess: () => {
            setInnerLoading(false);
          },
        });
        getInnerLicenses({ ...selPeriod });
        getInnerVehicles({
          ...selPeriod,
        });
      }
    }
  }, [selPeriod]);

  const retrieveLocations = () => {
    const citiesResults = new Array();

    registeredUsers.data.map((period) => {
      Object.keys(period.byLocation).map((loc) => {
        if (citiesResults?.filter((city) => city.value === loc).length < 1) {
          citiesResults.push({
            label: null,
            value: loc,
          });
        }
      });
    });

    citiesResults.map((obj) => {
      var sum = 0;
      registeredUsers.data.map((period) => {
        if (period.byLocation[obj.value]) sum += period.byLocation[obj.value];
      });
      citiesResults.push({
        label: sum,
        value: obj.value,
      });
    });

    return citiesResults.filter((obj) => obj.label).sort((a, b) => b.label - a.label);
  };

  return (
    <>
      <div className="statistics-sessions-container col">
        {innerLoading === true ? (
          <Statistics.LoaderInline />
        ) : (
          <>
            <div style={{ display: "flex", width: "100%" }}>
              <h2 className="main-header">
                <b>user profiles</b>
              </h2>
              <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} startField={"startDate"} endField={"endDate"} />
            </div>
            <div className="border-container background" style={{ marginRight: "1%" }}>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div className="indicator-circle dark-gray" />
                <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "17px" }}>
                  <b style={{ fontSize: "19px" }}>
                    {registeredUsers?.data
                      ? registeredUsers?.data?.reduce((ac, obj) => {
                          return ac + obj.total;
                        }, 0)
                      : registeredUsers?.total}
                  </b>{" "}
                  REGISTERED USERS
                </h2>
              </div>
            </div>
            <div className="outer-container">
              {osContainers.map((obj, i) => {
                return (
                  <div className="border-container" key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className="indicator-circle gray" />
                      <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "18px" }}>
                        {registeredUsers?.data
                          ? registeredUsers?.data?.reduce((ac, object) => {
                              return ac + object.byOS?.[obj.field];
                            }, 0)
                          : registeredUsers?.byOS?.[obj.field] || "---"}{" "}
                        <p style={{ fontSize: "15px" }}>{obj.text}</p>
                      </h2>
                    </div>
                    {/* <div className="info-icon black" />*/}
                  </div>
                );
              })}
              {genderContainers.map((obj, i) => {
                return (
                  <div className="border-container" key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className="indicator-circle gray" />
                      <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "18px" }}>
                        {registeredUsers?.data
                          ? registeredUsers?.data?.reduce((ac, object) => {
                              return ac + object.byGender?.[obj.field];
                            }, 0)
                          : registeredUsers?.byGender?.[obj.field] || "---"}{" "}
                        <p style={{ fontSize: "15px" }}>{obj.text}</p>
                      </h2>
                    </div>
                    {/* <div className="info-icon black" />*/}
                  </div>
                );
              })}
            </div>
            <div className="statistics-sessions-content">
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>USER VERIFICATION</h4>
                {registeredUsers.loading || incomes.loading ? (
                  <Statistics.LoaderInline />
                ) : (
                  <>
                    <Statistics.Pie
                      colors={["#0077FF", "#002C78"]}
                      height
                      data={[
                        {
                          label: registeredUsers?.data
                            ? registeredUsers?.data?.reduce((ac, object) => {
                                return ac + object.byVerification?.verified;
                              }, 0)
                            : registeredUsers?.byVerification?.verified,
                          value: "VERFIIED",
                        },
                        {
                          label: registeredUsers?.data
                            ? registeredUsers?.data?.reduce((ac, object) => {
                                return ac + object.byVerification?.unverified;
                              }, 0)
                            : registeredUsers?.byVerification?.unverified,
                          value: "UNVERIFIED",
                        },
                      ]}
                    />
                  </>
                )}
              </div>
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>PREMIUM USERS</h4>
                {registeredUsers.loading || incomes.loading ? (
                  <Statistics.LoaderInline />
                ) : (
                  <>
                    <Statistics.Pie
                      colors={["#0077FF", "#002C78"]}
                      height
                      data={[
                        {
                          label: registeredUsers?.data
                            ? registeredUsers?.data?.reduce((ac, object) => {
                                return ac + object.byAccountType?.premium;
                              }, 0)
                            : registeredUsers?.byAccountType?.premium,
                          value: "PREMIUM",
                        },
                        {
                          label: registeredUsers?.data
                            ? registeredUsers?.data?.reduce((ac, object) => {
                                return ac + object.byAccountType?.free;
                              }, 0)
                            : registeredUsers?.byAccountType?.free,
                          value: "FREE",
                        },
                      ]}
                    />
                  </>
                )}
              </div>
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>ADDED VEHICLES</h4>
                {innerVehicles.loading ? (
                  <Statistics.LoaderInline />
                ) : (
                  <>
                    <Statistics.Horizontal
                      data={
                        innerVehicles &&
                        Object.keys(innerVehicles)
                          ?.filter((veh) => veh !== "loading" && veh !== "type")
                          ?.map((veh) => {
                            return {
                              value: veh,
                              label: innerVehicles?.[veh],
                            };
                          })
                      }
                    />
                  </>
                )}
              </div>
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>ADDED DRIVER'S LICENSES</h4>
                {innerLicenses.loading ? (
                  <Statistics.LoaderInline />
                ) : (
                  <>
                    <Statistics.Horizontal
                      data={
                        innerLicenses &&
                        Object.keys(innerLicenses)
                          ?.filter((lic) => lic !== "loading" && lic !== "type")
                          ?.map((lic) => {
                            return {
                              value: lic,
                              label: innerLicenses?.[lic],
                            };
                          })
                      }
                    />
                  </>
                )}
              </div>
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>AGE GROUP</h4>
                {registeredUsers.loading || incomes.loading ? (
                  <Statistics.LoaderInline />
                ) : (
                  <>
                    <Statistics.Pie
                      colors={["#0077FF", "#002C78", "#831DCF", "#15DD95", "#1F92E6"]}
                      height
                      data={yearContainer.map((year) => {
                        return {
                          label: registeredUsers?.data
                            ? registeredUsers?.data?.reduce((ac, object) => {
                                return ac + object.byAgeGroup?.[year];
                              }, 0)
                            : registeredUsers?.byAgeGroup?.[year],
                          value: year,
                        };
                      })}
                    />
                  </>
                )}
              </div>
              <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
                <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>LOCATION</h4>
                {registeredUsers.loading ? (
                  <Statistics.LoaderInline />
                ) : (
                  <>
                    {registeredUsers?.data ? (
                      <Statistics.Horizontal data={retrieveLocations()} />
                    ) : (
                      <Statistics.Horizontal
                        data={
                          registeredUsers?.byLocation &&
                          Object.keys(registeredUsers?.byLocation)?.map((loc) => {
                            return {
                              value: loc,
                              label: registeredUsers?.byLocation?.[loc],
                            };
                          })
                        }
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

//TO-DO: call the other api-s in the all period + fix last horizontal

const mapStateToProps = (state) => ({
  incomes: state.dashboard.incomes,
  registeredUsers: state.dashboard.registeredUsers,
  innerVehicles: state.dashboard.innerVehicles,
  innerLicenses: state.dashboard.innerLicenses,
});
const mapDispatchToProps = (dispatch) => ({
  getIncomeRevenue: (payload) => dispatch(getIncomeRevenue(payload)),
  getInnerLicenses: (payload) => dispatch(getInnerLicenses(payload)),
  getInnerVehicles: (payload) => dispatch(getInnerVehicles(payload)),
  getRegisteredUsers: (payload) => dispatch(getRegisteredUsers(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers);
