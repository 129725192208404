/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getOrderedPolicies } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";

const headerElements = [
  {
    name: "User",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Payment date",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Type of insurance",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Insurance company",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Delivery price",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Installments",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Amount",
    query: { filter: "Role", options: [] },
  },
];

const OrderedInsurances = ({ getOrderedPolicies, orderedPolicies, period, setLoading, loading, filtered }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && orderedPolicies.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getOrderedPolicies({
        pageNumber: curPage,
        limit: 20,
        ...period,
        createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day").toISOString(),
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    setLoading(true);
    getOrderedPolicies({
      pageNumber: 1,
      limit: 20,
      ...period,
      createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day").toISOString(),
      onSuccess: (res) => {
        setLoading(false);
        setCurPage(2);
      },
    });
  }, []);

  useEffect(() => {
    if (filtered) {
      setLoading(true);
      getOrderedPolicies({
        pageNumber: 1,
        limit: 20,
        ...period,
        createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day").toISOString(),
        onSuccess: (res) => {
          setLoading(false);
          setCurPage(2);
        },
      });
    }
  }, [period, filtered]);

  return (
    <>
      {loading === true ? (
        <div style={{ height: "90%" }}>{<Statistics.LoaderInline center />}</div>
      ) : (
        <>
          <h2 style={{ marginBottom: "20px", textTransform: "uppercase" }}>users with purchased policies</h2>
          <Scrollbars
            style={{
              height: "92%",
              width: window.innerWidth < 1200 ? "160%" : "100%",
            }}
            onUpdate={handleUpdate}
            ref={tableRef}
            id={"scrollbar"}
            renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}
          >
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "12%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {orderedPolicies?.docs?.map((policy, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "12%" }}>
                    {policy?.fullName || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {policy?.phoneNumber || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {moment(policy?.createdAt).format("DD.MM.YYYY") || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {policy?.policyType || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {policy?.insuranceAgency || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {policy?.deliveryPrice + " BGN" || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {policy?.installments?.map((install, i) => (policy?.installments?.length - 1 > i ? install + " | " : install)) || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {policy?.premium + " BGN" || "---"}
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  orderedPolicies: state.app.orderedPolicies,
});
const mapDispatchToProps = (dispatch) => ({
  getOrderedPolicies: (payload) => dispatch(getOrderedPolicies(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OrderedInsurances);
