/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { logOut } from "../../actions";
import { connect, useSelector } from "react-redux";
import "./styles.scss";
import { User } from "../../utilites/User";
import Popup from "reactjs-popup";
import ExportOptionsPopup from "../ExportOptionsPopup";
import { exportDashboardStatistics } from "../../utilites/exportDashboardStatistics";
import { usersWithDisabledTasks } from "../../config/constants";

function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        document.getElementById("drowdown-content").style.display = "none";
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = ({ ref, logOut }) => {
  const { isLimited } = useSelector(({ login }) => login);
  const user = useSelector(({ login }) => login);
  const location = useLocation().pathname;
  const [showDropdown, setShowDropdown] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  console.log(User);
  console.log(user);

  return (
    <div className="header-container">
      <div className="left-header-container">
        <div className="logo" onClick={() => (isLimited ? history.push("/tasks") : history.push("/"))} />
      </div>

      <div className={`middle-header-container`}>
        {User.getRole() === "admin" && (
          <>
            {!isLimited && (
              <>
                <div className={`header-element ${location === "/" && "selected"}`} onClick={() => (isLimited ? history.push("/tasks") : history.push("/"))}>
                  <div className={`header-inner-element ${location === "/" && "selected"}`}>DASHBOARD</div>
                </div>
                <div className={`header-element ${location === "/app" && "selected"}`} onClick={() => history.push("/app")}>
                  <div className={`header-inner-element ${location === "/app" && "selected"}`}>APP</div>
                </div>
                <div className={`header-element ${location === "/website" && "selected"}`} onClick={() => history.push("/website")}>
                  <div className={`header-inner-element ${location === "/website" && "selected"}`}>WEBSITE</div>
                </div>
                <div className={`header-element ${location === "/finances" && "selected"}`} onClick={() => history.push("/finances")}>
                  <div className={`header-inner-element ${location === "/finances" && "selected"}`}>FINANCES</div>
                </div>
              </>
            )}
            {!usersWithDisabledTasks.includes(User.getPhoneNumber()) && (
              <div className={`header-element ${location === "/tasks" && "selected"}`} onClick={() => history.push("/tasks")}>
                <div className={`header-inner-element ${location === "/tasks" && "selected"}`}>TASKS</div>
              </div>
            )}
          </>
        )}
        {!isLimited && (
          <>
            <div className={`header-element ${location === "/advertisers" && "selected"}`} onClick={() => history.push("/advertisers")}>
              <div className={`header-inner-element ${location === "/advertisers" && "selected"}`}>ADVERTISERS</div>
            </div>
            {User.getRole() === "admin" && (
              <div className={`header-element ${location === "/discounts" && "selected"}`} onClick={() => history.push("/discounts")}>
                <div className={`header-inner-element ${location === "/discounts" && "selected"}`}>DISCOUNTS</div>
              </div>
            )}
          </>
        )}
      </div>

      <div className="right-container">
        {location === "/" && (
          <Popup position="bottom right" trigger={<div className="export-icon" />} contentStyle={{ width: "200px" }}>
            {(close) => <ExportOptionsPopup close={close} handleExport={exportDashboardStatistics} />}
          </Popup>
        )}
        <div
          className={`dropdown ${showDropdown && "show"}`}
          onClick={() => {
            if (showDropdown) setShowDropdown(false);
            else setShowDropdown(true);
          }}
        ></div>
        {showDropdown && (
          <>
            <div className="dropdown-content" ref={wrapperRef} id="drowdown-content">
              <div className="profile">
                <div className="text">Menu</div>
                <div className="exit" onClick={() => setShowDropdown(false)}></div>
              </div>
              <div className="info">
                <div className="avatar" />
                <div
                  style={{
                    height: "100%",
                    width: "91%",
                    padding: "0.6vw",
                    marginLeft: "3%",
                  }}
                >
                  {" "}
                  <div className="name">
                    <b>{User.user.name + " " + User.user.lastName}</b>
                  </div>
                  <div className="phone-number">{User.getUser().email}</div>
                </div>
              </div>
              <div
                className="log-out"
                onClick={() => {
                  logOut();
                  history.push("/login");
                }}
              >
                <div className="log-out-text">Log out</div>
                <div className="log-out-icon"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});
export default connect(null, mapDispatchToProps)(Header);
