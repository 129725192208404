export const authTypes = {
  REQUEST_CODE: "auth/REQUEST_CODE",
  ACTIVATE_CODE: "auth/ACTIVATE_CODE",
  LOG_IN: "auth/LOG_IN",
  LOG_IN_SUCCESS: "auth/LOG_IN_SUCCESS",
  LOGIN_SUCCESS_LOCAL_STORAGE: "login/LOGIN_SUCCESS_LOCAL_STORAGE",
  SET_IS_LIMITED: "auth/SET_IS_LIMITED",
  LOG_OUT: "auth/LOG_OUT",
  LOG_OUT_SUCCESS: "auth/LOG_OUT_SUCCESS",
};

export const requestCode = (payload) => ({
  type: authTypes.REQUEST_CODE,
  payload,
});
export const activateCode = (payload) => ({
  type: authTypes.ACTIVATE_CODE,
  payload,
});
export const logIn = (payload) => ({
  type: authTypes.LOG_IN,
  payload,
});
export const loginSuccessFromLocalStorage = (payload) => ({
  type: authTypes.LOGIN_SUCCESS_LOCAL_STORAGE,
  payload,
});
export const setIsLimited = (payload) => ({
  type: authTypes.SET_IS_LIMITED,
  payload,
});
export const logOut = () => ({
  type: authTypes.LOG_OUT,
});
