/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
// import { history } from "../../config/stores";
import { useDispatch, useSelector } from "react-redux";
import { emit } from "../../utilites/helpers";
import TasksComponent from "../../components/TasksComponent/TasksComponent";
import "./styles.scss";
import TaskFilterPopup from "../../components/TaskFilterPopup/TaskFilterPopup";
import { usersWithDisabledTasks } from "../../config/constants";
import { User } from "../../utilites";
import { history } from "../../config/stores";

const Tasks = () => {
  const dispatch = useDispatch();
  const users = useSelector(({ tasks }) => tasks?.users) || [];
  const filters = useSelector(({ tasks }) => tasks?.filters) || [];
  const socket = useSelector(({ tasks }) => tasks?.taskSocket) || null;
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [filtersPayload, setFiltersPayload] = useState({
    startDate: null,
    endDate: null,
    priority: [],
    assignedTo: [],
  });

  useEffect(() => {
    if (usersWithDisabledTasks.includes(User.getPhoneNumber())) history.push("/");
    else if (socket) {
      emit(socket, { action: "task/getTasks", payload: {} });
      emit(socket, { action: "task/getUsers", payload: {} });
    }
  }, [socket]);

  return (
    <div className="main-container">
      <div className="body-container" style={{ height: "100%", marginTop: "0%" }}>
        <div className="flex-container full-width space-between">
          <h2 className="inner-title">ЗАДАЧИ</h2>
          {isFilterActive && <TaskFilterPopup data={users} type="tasks" hide={() => setIsFilterActive(false)} setFilters={(filters) => emit(socket, { action: "task/getTasks", payload: { filters: filters } })} filtersPayload={filtersPayload} setFiltersPayload={(value) => setFiltersPayload(value)} />}
          <div className="filter-icon" style={{ width: "40px", height: "50px" }} onClick={() => setIsFilterActive(true)} />
        </div>
        <TasksComponent />
      </div>
    </div>
  );
};

export default Tasks;
