/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { addOffice, editOffice, deleteOffice, getCurrentOffice } from "../../actions";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import "./styles.scss";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { Cities } from "../../config/cities";

const OfficeForm = ({ office, getCurrentOffice, addOffice, editOffice, deleteOffice }) => {
  const office_id = window.location.href.split("/")[5];
  const [editEnable, setEditEnable] = useState(false);

  useEffect(() => {
    getCurrentOffice({
      id: office_id,
      onSuccess: (res) => {
        setValue("name", res.name);
        setValue("location", res.location.coordinates);
        setValue("address", res.address);
        setValue("phoneNumber", res.phoneNumber);
        setValue("email", res.email);
      },
    });
  }, []);

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    name: "",
    location: "",
    email: "",
    address: "",
    phoneNumber: "",
  });

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part" style={{ width: "92%" }}>
          <div className="close-icon" onClick={() => history.push("/website")} />
          <h2 className="inner-title"> {office_id ? (editEnable === true ? "EDIT OFFICE" : "REVIEW OFFICE") : "ADD NEW OFFICE"}</h2>
        </div>
        {office_id ? (
          editEnable === true ? (
            <>
              <Inputs.Button
                text="DELETE"
                deleted
                style={{ width: "7%", marginRight: "2%" }}
                onClick={() =>
                  deleteOffice({
                    id: office_id,
                    onSuccess: (response) => {
                      toast.success("Article deleted successfully");
                      history.push("/website");
                    },
                  })
                }
              />
              <Inputs.Button
                text="SAVE"
                selected
                style={{ width: "7%", marginRight: "2%" }}
                onClick={handleSubmit((e) => {
                  editOffice({
                    _id: office_id,
                    ...e,
                    location: {
                      type: "Point",
                      coordinates: e.location,
                    },
                    onSuccess: (res) => {
                      toast.success("Office edited successfully");
                      history.push("/website");
                    },
                  });
                })}
              />
            </>
          ) : (
            <Inputs.Button text="EDIT" selected style={{ width: "7%" }} onClick={() => setEditEnable(true)} />
          )
        ) : (
          <Inputs.Button
            text="ADD"
            selected
            style={{ width: "7%" }}
            onClick={handleSubmit((e) => {
              addOffice({
                ...e,
                location: {
                  type: "Point",
                  coordinates: e.location,
                },
                onSuccess: (res) => {
                  toast.success("Location added successfully");
                  history.push("/website");
                },
              });
            })}
          />
        )}
      </div>
      <div className="body-container flex-container full-width" style={{ height: "15%" }}>
        <Controller
          control={control}
          name={"name"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.SingleSelect
              optionsArray={Cities}
              field="name"
              label={"City"}
              style={{ width: "25%" }}
              compulsory
              value={{ value: value, label: value }}
              disabled={office_id ? (!editEnable ? true : false) : false}
              className={error && "error"}
              onChange={(e) => {
                setValue("name", e.label);
              }}
            />
          )}
          rules={{ required: true }}
        />
        {office_id && !editEnable ? (
          <Inputs.TextInput label={"Address"} compulsory style={{ width: "30%", margin: "0px 10px" }} disabled value={watch("address")} />
        ) : (
          <Controller
            control={control}
            name={"location"}
            render={({ field: { value, onChange }, fieldState: { error } }) => <Inputs.GooglePlacesAutoComplete field="location" label={"Address"} style={{ width: "30%", margin: "0px 10px" }} compulsory edit={office_id && true} disabled={office_id ? (!editEnable ? true : false) : false} value={watch().address} setValue={setValue} />}
            rules={{ required: true }}
          />
        )}

        <Inputs.TextInput label={"Phone"} compulsory style={{ width: "20%", margin: "0px 10px" }} disabled={office_id ? (!editEnable ? true : false) : false} className={errors.phoneNumber && "failed"} {...register(`phoneNumber`, { required: true })} />
        <Inputs.TextInput label={"Email"} compulsory style={{ width: "20%", margin: "0px 10px" }} disabled={office_id ? (!editEnable ? true : false) : false} className={errors.email && "failed"} {...register(`email`, { required: true })} />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  office: state.offices.office,
});
const mapDispatchToProps = (dispatch) => ({
  addOffice: (payload) => dispatch(addOffice(payload)),
  deleteOffice: (payload) => dispatch(deleteOffice(payload)),
  editOffice: (payload) => dispatch(editOffice(payload)),
  getCurrentOffice: (payload) => dispatch(getCurrentOffice(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OfficeForm);
