/* eslint-disable */
import moment from "moment";
import * as XLSX from "xlsx";
import { API_URL } from "../config/settings";
import { Headers } from "./Headers";

export const getPeriod = (period) => {
  switch (period) {
    case "day":
      return { startDate: moment().subtract(1, "days").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z", endDate: moment().startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z", };
    case "week":
      return { startDate: moment().subtract(7, "days").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z", endDate: moment().startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z" };
    case "month":
      return { startDate: moment().subtract(31, "days").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z", endDate: moment().startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z" };
    case 'six-months':
      return { startDate: moment().subtract(6, "months").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z", endDate: moment().startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z" };
    case 'twelve-months':
      return { startDate: moment().subtract(1, "years").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z", endDate: moment().startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z" };
    case 'all':
      return { startDate: null, endDate: moment().startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z" };
    default:
      return {};
  }
};

export const splitPeriods = (months) => {
  var periods = new Array();

  while (months >= 0) {
    if (months - 2 < 0) {
      periods.push({
        startDate: moment(periods[periods.length - 1].endDate).toISOString(),
        endDate: moment().toISOString(),
      });
      break;
    } else {
      if (periods.length < 1) {
        periods.push({
          startDate: "2020-07-21T15:36:15.133+0000",
          endDate: moment("2020-07-21T15:36:15.133+0000").add(2, "months").toISOString(),
        });
      } else
        periods.push({
          startDate: moment(periods[periods.length - 1].endDate).toISOString(),
          endDate:
            moment(periods[periods.length - 1].endDate)
              .add(2, "months")
              .toISOString(),
        });
    }
    months -= 2;
  }

  return periods;
};

export const exportAppStatistics = async (targetUrl, setLoading) => {
  const downloads = new Promise((resolve, reject) => {
    fetch(`${API_URL}/finances/${targetUrl}`, {
      method: "POST",
      headers: Headers.get_auth(),
      body: JSON.stringify({
        forExport: true,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = `${targetUrl}.xlsx`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        setLoading(false);
        resolve();
      })
      .catch((error) => reject(error));
  });

  try {
    await Promise.all([downloads]);
  } catch (error) {
    console.log(error);
  }
};
